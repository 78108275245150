<template>
    <div class="open__menu__container">

        <div class="open__menu__list" :class="[openServicesMenu ? 'open__menu__list_services' : 'open__menu__list']">

            <ul class="open__menu__ul" v-if="!openServicesMenu">
                <li
                        @click="$emit('close')"
                        ref="menuItem1"
                        class="open__menu__item">
                  <router-link
                      class="open__menu__nuxt__link"
                      :class="{open__menu__nuxt__link_active: (this.$route.name === 'portfolio')}"

                      :to="{name: 'portfolio'}">
                    {{ this.menuForServices[0].name }}
                  </router-link>
                </li>

                <li
                        @click="$emit('close')"
                        ref="menuItem2"
                        class="open__menu__item">
                  <router-link
                      class="open__menu__nuxt__link"
                      :class="{open__menu__nuxt__link_active: (this.$route.name === 'blog')}"

                      :to="{name: 'blog'}">
                    {{ this.menuForServices[1].name }}
                  </router-link>
                </li>

                <li
                        @click="$emit('close')"
                        ref="menuItem3"
                        class="open__menu__item">
                  <router-link
                      class="open__menu__nuxt__link"
                      :class="{open__menu__nuxt__link_active: (this.$route.name === 'reel')}"
                      :to="{name: 'reel'}">
                    {{ this.menuForServices[2].name }}
                  </router-link>
                </li>

                <li
                        @click="$emit('close')"
                        ref="menuItem4"
                        class="open__menu__item">
                  <router-link
                      class="open__menu__nuxt__link"
                      :class="{open__menu__nuxt__link_active: (this.$route.name === 'about')}"

                      :to="{name: 'about'}">
                    {{ this.menuForServices[3].name }}
                  </router-link>
                </li>

                <li
                    @click="openServices"
                    ref="menuItem5"
                        class="open__menu__item">
                  <router-link
                      class="open__menu__nuxt__link"
                      :class="{open__menu__nuxt__link_active: (this.$route.name === 'services')}"
                      :to="{name: ''}">
                    {{ 'SERVICES' }}
                  </router-link>
                </li>

                <li
                        @click="$emit('close')"
                        ref="menuItem6"
                        class="open__menu__item">
                    <a
                            class="open__menu__nuxt__link"
                            href="#"
                            v-scroll-to="{ el: '#contact', duration: 1000 }"
                    >
                        {{ 'CONTACT' }}
                    </a>
                </li>


            </ul>
            <ul v-if="openServicesMenu && this.menuForServices" class="open__menu__ul flex__ul">
                <div @click="closeServices()" ref="menuItem1" class="drop__services"><a>SERVICES</a>
                    <img class="services__drop__active"
                         src="../assets/img/drop__services.svg" alt="drop__services">
                </div>
                <li @click="$emit('close')"
                    v-for="(item, index) in this.menuForServices[4].items"
                    :ref="`menuItem${index + 2}`"
                    :key="index" class="margin__item open__menu__item">
                    <router-link class="open__menu__nuxt__link " :to="`/services/${item.slug}`"
                                 @ckick="getLinkBySlug(item.slug)">{{
                            dotText(item.title)
                        }}
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="open__menu__container__social">
            <div class="open__menu__container__social__links">
                <a
                        class="open__menu__container__social__NuxtLinks__instagramm"
                        href="https://www.instagram.com/digitalbeautywork/">
                    <img alt="instagramm" src="@/assets/img/instagramm.png" class="open__menu__container__social__img">
                </a>
                <a
                        class="open__menu__container__social__NuxtLinks__linkedin"
                        href="https://www.linkedin.com/company/digital-beauty-work/">
                    <img alt="linkedin" src="@/assets/img/linkidin.png" class="open__menu__container__social__img">
                </a>
                <a
                        class="open__menu__container__social__NuxtLinks__facebook"
                        href="https://www.facebook.com/digitalbeautywork">
                    <img alt="facebook" src="@/assets/img/facebook.png"
                         class="open__menu__container__social__img__facebbok">
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        isOpen: {
            type: Boolean,
            require: true
        },
    },
    name: "OpenMenuFooter",
    data() {
        return {
            openServicesMenu: false,
            isActive: false,
            /*menu: [],*/
            servicesLinkBySlug: '',
            /*menuServices: [],*/
        }
    },
    computed: {
        ...mapGetters([
            'menuLinkText',
            'menuForServices'
        ])
    },
    mounted() {
        /*this.$store.dispatch('getMenuLinkText').then(() => {
            this.menu = this.menuLinkText;
        });
        this.$store.dispatch('getMenuServices').then(() => {
            this.menuServices = this.menuForServices;
        });*/
    },
    methods: {
        closeServices() {
            this.openServicesMenu = false
            this.openAnimation();
        },
        openServices() {
            this.openServicesMenu = true
            this.openAnimation();

        },
        dotText(text) {
            let sliced = text.slice(0, 16);
            if (sliced.length < text.length) {
                sliced += '...';
            }
            return sliced
        },
        getLinkBySlug(item) {
            this.servicesLinkBySlug = item
        },
        openAnimation() {
            setTimeout(() => {
                const list = document.getElementsByClassName('open__menu__ul');
                for (let item of list) {
                    for (let i = 0; i < item.children.length; i++) {
                        setTimeout(() => {
                            item.children[i].style.transform = 'translateY(0px)';
                            item.children[i].style.opacity = '1';
                        }, 50 * i)
                    }
                }
            }, 250)
        }
    },

    watch: {

        isOpen: function () {
            if (this.isOpen) {
                this.openAnimation();
            } else {
                this.openServicesMenu = false;
                for (let i = 6; i >= 1; i--) {
                    setTimeout(() => {
                        this.$refs[`menuItem${i}`].style.transform = 'translateY(-40px)';
                        this.$refs[`menuItem${i}`].style.opacity = '0';
                    }, 50 * i)
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.open__menu__container {
  display: none;
}

@keyframes showBackground {
  from {
    background-color: rgba(33, 33, 33, 0);
  }

  to {
    background-color: rgba(33, 33, 33, 100);
  }
}

@media (max-width: 981px) {
  .open__menu__list_services{
    transform: rotate(315deg);
    text-align: right;
    display: flex;
    justify-content: flex-end;
    width: 60.733vw;
    height: 70vw;
    position: absolute;
    top: 33vw!important;
    right: 30vw!important;

  }
    .services__drop__active {
        margin-left: 1vw;
        margin-bottom: 0.5vw;
    }
    .drop__services {
        margin-bottom: 2vw;
        font-size: 3.733vw;
        color: rgba(219, 16, 35, 1);
        display: flex;
        align-items: center;
        position: relative;
        z-index: 100;
        cursor: pointer;
    }
    .drop__services a {
        user-select: none;
    }
  .open__menu__container {
    transform: rotate(45deg);
    display: block;
    position: absolute;
    bottom: 39.067vw;
    right: -39.4vw;
    z-index: 99999 !important;

    /*
    background: red;
    */

    width: 106.667vw;
    height: 106.667vw;
    background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.774) 100%);

  }

  .open__menu__list {
    transform: rotate(315deg);
    text-align: right;
    display: flex;
      justify-content: flex-end;
      width: 60.733vw;
      height: 70vw;
      position: absolute;
      top: 19.867vw;
      right: 46.933vw;
  }
  .open__menu__ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .open__menu__item {
    text-transform: uppercase;

    list-style-type: none;
    /*margin-bottom: 10.667vw;*/
    transform: translateY(-40px);
    opacity: 0;
    transition: all .2s ease;
  }

  .open__menu__nuxt__link {
      font-size: 3.733vw;
    text-decoration: none;
    color: rgba(246, 242, 242, 1);
    font-family: 'Benzin-Regular';
    cursor: pointer;
    transition: 0.3s;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }
  .open__menu__nuxt__link:hover {
    color: rgba(219, 16, 35, 1);
  }

  .open__menu__nuxt__link_active {
    font-size: 3.733vw;
    text-decoration: none;
    font-family: 'Benzin-Regular';
    color: rgba(219, 16, 35, 1);
    cursor: pointer;
    transition: 0.3s;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;

  }

  .open__menu__container__social {
    position: absolute;
    bottom: 0;
    transform: rotate(315deg);
    width: 24.533vw;
    bottom: 15.200vw;
    right: 17.067vw;
  }
  .open__menu__container__social__img {
    width: 5.600vw;
    height: 5.600vw;
  }
  .open__menu__container__social__img__facebbok {
    width: 2.667vw;
    height: 5.600vw;
  }
    .flex__ul{
        /*justify-content: space-between;
        height: max-content;
        min-height: 21vw;
        margin-right: 3vw;
        align-items: flex-start;*/
      margin-right: 3vw;
      align-items: flex-start;
      justify-content: space-between;
      height: max-content;
      min-height: 21vw;
    }
    .margin__item {
        margin-button: 1.5vw!important;
    }


  .open__menu__container__social__NuxtLinks__instagramm {
    margin-right: 5.333vw;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .open__menu__container__social__NuxtLinks__facebook {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .open__menu__container__social__NuxtLinks__linkedin {
    margin-right: 5.333vw;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
  }
}


</style>